import styled from 'styled-components'

import { WHITE, GREY_80, SKY_BLUE, MID_BLUE, NAVY_BLUE } from 'components/shared/theme'

import BaseButton from './BaseButton'

/**
 * The Primary button component has a solid weight, directs attention to user to a primary action.
 */

const PrimaryButton = styled(BaseButton)`
  background-color: ${MID_BLUE};
  border-color: ${MID_BLUE};
  color: ${WHITE};

  &:hover {
    background-color: ${SKY_BLUE};
    border-color: ${SKY_BLUE};
  }

  &:active {
    background-color: ${NAVY_BLUE};
    border-color: ${NAVY_BLUE};
  }

  &:disabled {
    background-color: ${GREY_80};
    border-color: ${GREY_80};
    pointer-events: auto;
  }
`

/** @component */
export default PrimaryButton

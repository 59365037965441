import { faBell } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useQuery } from '@tanstack/react-query'
import React, { useCallback } from 'react'
import styled from 'styled-components'

import { SecondaryButton } from 'components/shared/Buttons'
import { NAVY_BLUE, STYLE_HEADING_3, WHITE } from 'components/shared/theme'
import { withReactQueryProvider } from 'helpers/withReactQueryProvider'
import fetchNews, { NewsData } from 'services/fetchNews'
import { trackEvent } from 'services/tracker'

import { NewsItem } from './NewsItem'

const Panel = styled.div`
  background-color: ${NAVY_BLUE};
  padding: 16px;
  border-radius: 4px;
  margin: 64px 0;
  @media (min-width: 768px) {
    width: calc(100% * 2 / 3);
  }
`

const Title = styled.header`
  ${STYLE_HEADING_3}
  color: ${WHITE};
  text-align: center;
`

const PanelIcon = styled(FontAwesomeIcon)`
  color: ${WHITE};
  font-size: 24px;
  float: left;
  top: 16px;
  left: 16px;
`

const List = styled.ul`
  list-style: none;
  padding: 0;
`

const AllUpdatesButton = styled(SecondaryButton).attrs({
  as: 'a',
})<{ href: string; target: string; rel: string }>`
  display: block;
  margin: 16px 32px;
  width: calc(100% - 64px);
`

export const News = withReactQueryProvider(() => {
  const {
    data: news,
    isLoading,
    error,
  } = useQuery<NewsData[]>({
    queryKey: ['news'],
    queryFn: fetchNews,
  })

  const handleAllUpdatesClick = useCallback(() => {
    trackEvent('Login', 'Click on all news')
  }, [])

  if (isLoading || error || !news) return null

  return (
    <Panel>
      <PanelIcon icon={faBell} />
      <Title>{t('login.news.title')}</Title>
      <List>
        {news.map((item) => (
          <NewsItem key={item.id} item={item} />
        ))}
      </List>
      <AllUpdatesButton
        href="https://www.samedi.com/samedi-neuerungen"
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleAllUpdatesClick}
      >
        {t('login.news.btn_all_updates')}
      </AllUpdatesButton>
    </Panel>
  )
})

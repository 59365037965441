import styled from 'styled-components'

import { GREY_30, GREY_80, GREY_90, SKY_BLUE, MID_BLUE } from 'components/shared/theme'

import BaseButton from './BaseButton'

/**
 * The Icon button component should only be used with icons and no text.
 */

const IconButton = styled(BaseButton)`
  color: ${GREY_30};
  background: none;
  padding: 7px;
  line-height: 0; /* to avoid inheriting the line-height from parent BaseButton and to have a height equal to that of the icon */
  display: inline-block;

  &:hover {
    color: ${SKY_BLUE};
    border: 1px solid ${GREY_90};
  }

  &:active {
    color: ${MID_BLUE};
    border: 1px solid ${GREY_90};
  }

  &:disabled {
    color: ${GREY_80};
    border-color: transparent;
  }
`

/** @component */
export default IconButton

import styled from 'styled-components'

import {
  GREY_30,
  GREY_80,
  MID_BLUE,
  SKY_BLUE,
  SKY_BLUE_A30,
  SPACING,
  WHITE,
} from 'components/shared/theme'

import BaseButton from './BaseButton'

/**
 * The Ghost Icon button component can only be used with icons and no text.
 */

const COLORS = {
  default: MID_BLUE,
  inverted: WHITE,
  muted: GREY_30,
} as const

export interface Props {
  variant?: keyof typeof COLORS
}

const getColor = ({ variant = 'default' }: Props) => COLORS[variant]

const GhostIconButton = styled(BaseButton)<Props>`
  color: ${getColor};
  background: none;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${3 * SPACING}px;
  width: ${3 * SPACING}px;
  padding: 0;

  &:hover {
    color: ${getColor};
    background: ${SKY_BLUE_A30};
  }

  &:active {
    color: ${WHITE};
    background: ${SKY_BLUE};
  }

  &:disabled {
    color: ${GREY_80};
  }
`

/** @component */
export default GhostIconButton

import { faGreaterThan } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'
import React, { useCallback } from 'react'
import styled from 'styled-components'

import { WHITE, STYLE_DEFAULT_TEXT, STYLE_LABEL_TEXT } from 'components/shared/theme'
import { NewsData } from 'services/fetchNews'
import { trackEvent } from 'services/tracker'

const ListItem = styled.li`
  border-bottom: 1px solid ${WHITE};
  list-style: none;
  padding: 8px 0;
  margin: 0 32px;
  display: flex;
  align-items: center;
`

const NewsDescription = styled.div`
  ${STYLE_DEFAULT_TEXT}
  color: ${WHITE};
`

const NewsTitle = styled.div`
  ${STYLE_LABEL_TEXT}
  color: ${WHITE};
  font-weight: normal;
`

const ArrowIcon = styled(FontAwesomeIcon)`
  color: ${WHITE};
  font-size: 14px;
  font-weight: bold;
  margin-left: 8px;
`

const LeftCol = styled.div`
  flex: 1;
  flex-grow: 1;
`

const RightCol = styled.div`
  flex: 1;
  flex-grow: 0;
  text-align: right;
`

export const NewsItem = ({ item }: { item: NewsData }) => {
  const handleClick = useCallback(() => {
    trackEvent('Login', 'Click Read News', item.description)
  }, [item.description])

  return (
    <a href={item.link} target="_blank" rel="noopener noreferrer" onClick={handleClick}>
      <ListItem key={item.id}>
        <LeftCol>
          <NewsDescription>{item.description}</NewsDescription>
          <NewsTitle>
            {item.title} {format(new Date(item.updated_at), t('login.news.date_format'))}
          </NewsTitle>
        </LeftCol>
        <RightCol>
          <ArrowIcon icon={faGreaterThan} />
        </RightCol>
      </ListItem>
    </a>
  )
}

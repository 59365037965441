import styled from 'styled-components'

interface Props {
  iconPosition?: 'left' | 'right'
}

/**
 * Wrapper to be used on buttons which have an icon + label as content.
 * It takes care of adding any necessary style and spacing between the
 * content elements.
 */
export const IconWithLabel = styled.div<Props>`
  display: inline-block;

  > svg,
  .fa,
  [role='img'] {
    ${({ iconPosition = 'left' }) => {
      if (iconPosition === 'left') {
        return 'margin-right: 8px;'
      }
      if (iconPosition === 'right') {
        return 'margin-left: 8px;'
      }
    }}
  }
`

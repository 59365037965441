import styled from 'styled-components'

import { WHITE, GREY_80, MID_BLUE, SKY_BLUE, NAVY_BLUE } from 'components/shared/theme'

import BaseButton from './BaseButton'

/**
 * The Secondary button component has lighter weight, used for a secondary action
 * or to reduce visual noise when there are many buttons.
 */

const SecondaryButton = styled(BaseButton)`
  background-color: ${WHITE};
  border-color: ${MID_BLUE};
  color: ${MID_BLUE};

  &:hover {
    border-color: ${SKY_BLUE};
    color: ${SKY_BLUE};
  }

  &:active {
    border-color: ${NAVY_BLUE};
    color: ${NAVY_BLUE};
  }

  &:disabled {
    border-color: ${GREY_80};
    color: ${GREY_80};
  }
`

/** @component */
export default SecondaryButton

/**
 * A t() helper similar to Ruby's I18n gem t method
 *
 * Translations for the current locale are expected to be found in a
 * +translations+ hash (taken care by the back-end)
 *
 * Also possible to provide interoplation parameters, e.g.:
 *
 * t("Hello %s", username)
 */

const interpolationPattern = /%\{(\w+)\}/g
const legacyInterpolationPattern = /%s/g

interface Translation {
  [key: string]: string
}

function parseInterpolations(values: Array<Object | string>) {
  const legacyInterpolations: string[] = []
  let interpolations: Translation = {}

  for (const val of values) {
    if (typeof val === 'string' || typeof val === 'number') {
      legacyInterpolations.push(String(val))
    } else {
      interpolations = Object.assign({}, interpolations, val)
    }
  }

  return { interpolations, legacyInterpolations }
}

export function replaceTranslationPatterns(term: string, ...values: Array<Object | string>) {
  const { interpolations, legacyInterpolations } = parseInterpolations(values)

  return (
    term &&
    term
      .replace(interpolationPattern, (key, val) => interpolations[val] || key)
      .replace(legacyInterpolationPattern, () => legacyInterpolations.shift() || '%s')
  )
}

window.translations = window.translations || {}

function t(key: string, ...values: Array<Object | string>) {
  if (key === undefined) return

  const translation =
    window.translations[key] === undefined ? defaultTranslation(key) : window.translations[key]

  if (translation === undefined) return

  return replaceTranslationPatterns(translation, ...values)
}

function defaultTranslation(key: string) {
  return key ? key.split('.')[key.split('.').length - 1] : undefined
}

export default t

import styled from 'styled-components'

import { STYLE_BUTTON_TEXT, DEFAULT_FONT_FAMILY } from 'components/shared/theme'

/**
 * Private Base component for buttons.
 */

export interface BaseButtonProps {
  fullWidth?: boolean
}

const BaseButton = styled.button<BaseButtonProps>`
  ${STYLE_BUTTON_TEXT}

  border-radius: 5px;
  border: 1px solid transparent;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  font-family: ${DEFAULT_FONT_FAMILY};
  outline: none;
  padding: 7px 15px;
  text-align: center;
  text-overflow: ellipsis;
  transition:
    background-color 0.25s ease-out,
    color 0.25s ease-out;
  white-space: nowrap;
  ${({ fullWidth = false }) => fullWidth && 'width: 100%;'}

  &::-moz-focus-inner {
    border: 0;
  }
`

/** @component */
export default BaseButton

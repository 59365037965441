import styled from 'styled-components'

import { WHITE, GREY_80, SKY_BLUE, MID_BLUE, NAVY_BLUE } from 'components/shared/theme'

import BaseButton from './BaseButton'

/**
 * The Primary icon button component has a solid weight, directs attention to user to a primary action.
 */

const PrimaryIconButton = styled(BaseButton)`
  background-color: ${MID_BLUE};
  border-color: ${MID_BLUE};
  color: ${WHITE};
  display: inline-block;
  line-height: 0; /* to avoid inheriting the line-height from parent BaseButton and to have a height equal to that of the icon */

  &:hover {
    background-color: ${SKY_BLUE};
    border-color: ${SKY_BLUE};
  }

  &:active {
    background-color: ${NAVY_BLUE};
    border-color: ${NAVY_BLUE};
  }

  &:disabled {
    background-color: ${GREY_80};
    border-color: ${GREY_80};
  }
`

/** @component */
export default PrimaryIconButton

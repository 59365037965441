import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import React from 'react'

export const queryClient = new QueryClient()

export const withReactQueryProvider = <T extends {}>(Component: React.ComponentType<T>) =>
  function withReactQueryProvider(props: T) {
    return (
      <QueryClientProvider client={queryClient}>
        <Component {...props} />
      </QueryClientProvider>
    )
  }

/* eslint-disable camelcase */
import axios from 'axios'

export interface NewsData {
  id: string
  title: string
  description: string
  link: string
  updated_at: string
  category: string
}

export default async function fetchNews() {
  const response = await axios.get<NewsData[]>('/api/dashboard/v1/news.json')
  return response.data
}

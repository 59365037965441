import styled from 'styled-components'

import PrimaryButton from './PrimaryButton'

/**
 * Extension of PrimaryButton with increased padding
 */

const BigPrimaryButton = styled(PrimaryButton)`
  padding-top: 13px;
  padding-bottom: 13px;
`

/** @component */
export default BigPrimaryButton

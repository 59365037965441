import styled from 'styled-components'

import { WHITE, MID_BLUE, SKY_BLUE, NAVY_BLUE, GREY_80 } from 'components/shared/theme'

import BaseButton from './BaseButton'

/**
 * The Plain button component shows plain text, used for a tertiary action, can be used inline.
 */

const PlainButton = styled(BaseButton)`
  background-color: ${WHITE};
  border-color: ${WHITE};
  color: ${MID_BLUE};

  &:hover {
    color: ${SKY_BLUE};
  }

  &:active {
    color: ${NAVY_BLUE};
  }

  &:disabled {
    color: ${GREY_80};
  }
`

/** @component */
export default PlainButton
